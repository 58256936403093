/**
 * Created by preference on 2021/09/07
 *  zmx 
 */

 import * as API from '@/api/index'
 export default {
   /** 列表  */
   entityTreatGoodsDetailStatement_list: params => {
     return API.POST('api/entityTreatGoodsDetailStatement/list', params)
   },
   /**   */
   entityTreatGoodsDetailStatement_excel: params => {
     return API.exportExcel('api/entityTreatGoodsDetailStatement/excel', params)
   },

  
  /** 9.1.项目品牌列表（不分页）  */
  getProjectBrandList: params => {
    return API.POST('api/projectBrand/all', params)
  },
  
  // 项目分类列表
  getValidProjectCategoryValid: () => {
      return API.POST('api/projectCategory/valid')
  },
 }